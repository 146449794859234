// src/components/Footer.tsx

import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/footer.module.css";

const Footer: React.FC = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerContent}>
                <h3>No Pints Beer Crawl</h3>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/ballard">Ballard</Link>
                    </li>
                    <li>
                        <Link to="/sandiego">San Diego</Link>
                    </li>
                    <li>
                        <Link to="/chicago">Chicago (Coming Soon)</Link>
                    </li>
                    <li>
                        <Link to="/about-us">About Us</Link>
                    </li>
                </ul>
            </div>
            <div className={styles.footerCopy}>
                <p>&copy; {new Date().getFullYear()} No Pints Beer Crawl. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
