import React, { useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Testimonials from "./components/Testimonials";
import Footer from "./components/Footer";
import AboutUsPage from "./pages/AboutUsPage";
import BallardCityPage from "./pages/BallardCityPage";
import SanDiegoCityPage from "./pages/SanDiegoCityPage";
import ChicagoComingSoonPage from "./pages/ComingSoonPage";
import "./styles/global.css";
import ScrollToTop from "./components/ScrollToTop";

const App: React.FC = () => {
    const myRef = useRef(null);

    return (
        <Router>
            <ScrollToTop />
            <div className="App">
                <Header />
                <Routes>
                    <Route
                        path="/"
                        element={
                            <>
                                <Hero />
                                <Testimonials />
                            </>
                        }
                    />
                    <Route path="/sandiego" element={<SanDiegoCityPage />} />
                    <Route path="/ballard" element={<BallardCityPage />} />
                    <Route
                        path="/chicago"
                        element={<ChicagoComingSoonPage />}
                    />
                    <Route path="/about-us" element={<AboutUsPage />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
};

export default App;
