// src/components/Hero.tsx

import React, { useRef } from "react";
import styles from "../styles/hero.module.css";
import BALLARD_PNG from "../images/ballard.png";
import SANDIEGO_PNG from "../images/sandiego.png";
import CHICAGO_PNG from "../images/chicago.png";
import { Link, useNavigate } from "react-router-dom";

interface Props {}

const Hero: React.FC<Props> = () => {
    const myRef = useRef(null);
    const executeScroll = () => (myRef.current as any).scrollIntoView();
    const navigate = useNavigate();

    const cities = [
        {
            name: "Ballard, Seattle",
            image: BALLARD_PNG,
            link: "/ballard",
            description:
                "Discover the thriving craft beer scene in Ballard, Seattle, as you taste your way through local breweries, enjoying a diverse range of beer samplers and learning about the unique brewing culture.",
            buttonText: "Explore Ballard",
        },
        {
            name: "San Diego",
            image: SANDIEGO_PNG,
            link: "/sandiego",
            description:
                "Experience the vibrant beer scene of San Diego, where you can sample a wide variety of craft brews from the city's finest breweries and learn about their unique brewing techniques.",
            buttonText: "Explore San Diego",
        },
        {
            name: "Chicago (Coming Soon)",
            image: CHICAGO_PNG,
            link: "/chicago",
            description:
                "Stay tuned for our upcoming beer crawl in Chicago, a city with a rich brewing history and an exciting array of modern craft breweries to discover.",
            buttonText: "Coming Soon",
        },
    ];

    return (
        <>
            <section className={styles.hero}>
                <h1>Discover the Taste of Adventure: One Sampler at a Time</h1>
                <p>
                    Embark on a unique beer crawl experience in your favorite
                    cities , exploring local breweries and savoring a diverse
                    range of beer samplers. No pints required!
                </p>
                <button onClick={executeScroll}>
                    Choose Your City and Start Your Adventure
                </button>
            </section>
            <section className={styles.featuredCities}>
                <h2 ref={myRef}>Featured Cities</h2>
                <div className={styles.citiesContainer}>
                    {cities.map((city, index) => (
                        <div key={index} className={styles.city}>
                            <img src={city.image} alt={city.name} />
                            <h3>{city.name}</h3>
                            <p>{city.description}</p>
                            <button
                                onClick={() => {
                                    navigate(city.link);
                                }}
                                disabled={city.buttonText === "Coming Soon"}
                            >
                                {city.buttonText}
                            </button>
                        </div>
                    ))}
                </div>
            </section>
        </>
    );
};

export default Hero;
