// src/pages/AboutUsPage.tsx

import React from "react";
import styles from "../styles/aboutUsPage.module.css";
import STEN from "../images/sten_and_martha.png";

interface Props {}

const AboutUsPage: React.FC<Props> = () => {
    return (
        <div className={styles.aboutUsPage}>
            <h1>About Us</h1>
            <img src={STEN} alt="Sten and Martha" style={{ display: "flex", margin: "auto", height: "500px" }} />

            <section>
                <h2>Sten & Martha - The Founders</h2>
                <p>
                    Sten and Martha, a loving couple who've been together for over 35 years, have always shared a
                    passion for exploring new places and trying new things. As they grew older, their love for beer only
                    grew stronger, and they discovered the joy of visiting local breweries and immersing themselves in
                    the vibrant craft beer culture.
                </p>
                <p>
                    Over the years, they traveled to numerous cities across the United States, sampling a wide variety
                    of beers at countless breweries. However, they soon realized that drinking full pints limited their
                    ability to try more unique brews during their adventures. That's when they decided to focus on
                    tasting smaller pours, allowing them to sample a broader range of beers without overindulging.
                </p>
                <p>
                    This new approach to beer tasting revitalized their brewery visits, as they found that the best part
                    of drinking beer was not just the beer itself, but the entire experience. They enjoyed being
                    outside, discovering new flavors, and most importantly, sharing these moments with friends and
                    fellow beer enthusiasts.
                </p>
                <p>
                    Inspired by their newfound appreciation for beer tasting, Sten and Martha decided to create No Pints
                    Beer Crawl. Their vision was to share their unique beer crawl experiences with others, promoting the
                    idea of savoring a variety of smaller beer samples, rather than just a few full pints.
                </p>
                <p>
                    Today, No Pints Beer Crawl has grown into a thriving community of like-minded beer lovers who
                    appreciate the joy of exploring new breweries, sampling diverse flavors, and connecting with others
                    who share their passion. As the founders, Sten and Martha continue to travel, actively scouting new
                    cities and curating the best beer crawl experiences for their community.
                </p>
            </section>
        </div>
    );
};

export default AboutUsPage;
