// src/pages/cityPage.tsx

import React from "react";
import styles from "../styles/cityPage.module.css";
import MAP from "../images/map_sandiego.png";
import SANDIEGO_PNG from "../images/sandiego.png";

interface Props {}

const SanDiegoCityPage: React.FC<Props> = () => {
    return (
        <div className={styles.cityPage}>
            <h1>San Diego City Beer Crawl</h1>
            {/* City Introduction */}
            <section>
                <h1>A Craft Beer Paradise</h1>
                <p>
                    Welcome to San Diego, a city known for its stunning beaches, perfect weather, and exceptional craft
                    beer scene. With over 150 breweries in the region, San Diego is a beer lover's dream destination.
                    Embark on a No Pints Beer Crawl and discover the unique flavors and local gems that make this city a
                    true craft beer paradise.
                </p>
                <img
                    src={SANDIEGO_PNG}
                    style={{ maxWidth: "80%", maxHeight: "500px", margin: "auto", display: "flex" }}
                    alt="Ballard, Seattle"
                />
            </section>

            {/* Recommended Breweries */}
            <section>
                <h2>Recommended Breweries</h2>
                <ul>
                    <li>
                        <strong>Stone Brewing:</strong> One of the largest craft breweries in the United States, Stone
                        Brewing is known for its bold, hop-forward beers, such as the iconic Stone IPA. Visit their
                        beautiful brewery and gardens in Escondido for a memorable beer experience.
                    </li>
                    <li>
                        <strong>Ballast Point Brewing Company:</strong> With multiple locations across San Diego,
                        Ballast Point is famous for their Sculpin IPA and a wide range of innovative, high-quality
                        beers. Their waterfront tasting room in Little Italy offers stunning views and delicious brews.
                    </li>
                    <li>
                        <strong>AleSmith Brewing Company:</strong> AleSmith Brewing Company is an award-winning brewery
                        specializing in handcrafted ales inspired by European classics. Be sure to try their Speedway
                        Stout, a rich and flavorful imperial stout with a cult following.
                    </li>
                    <li>
                        <strong>Modern Times Beer:</strong> Known for their eccentric branding and delicious, creative
                        beers, Modern Times Beer is a must-visit destination in San Diego. Their tasting room, dubbed
                        the "Flavordome," offers a unique and fun atmosphere to enjoy their exceptional brews.
                    </li>
                    <li>
                        <strong>Societe Brewing Company:</strong> Societe Brewing Company is dedicated to crafting
                        high-quality, flavorful beers with an emphasis on hoppy ales and Belgian-inspired brews. Their
                        stylish tasting room is the perfect place to savor their diverse offerings.
                    </li>
                    <li>
                        <strong>Green Flash Brewing Co.:</strong> A pioneer in the San Diego craft beer scene, Green
                        Flash Brewing Co. is known for their bold, hoppy beers. Their tasting room offers a wide
                        selection of their classic brews, as well as innovative small-batch creations.
                    </li>
                </ul>
            </section>

            {/* Beer Crawl Map */}
            <section>
                <h2>Beer Crawl Map</h2>
                <div className={styles.map}>{<img src={MAP} />}</div>
            </section>

            {/* How It Works */}
            <section>
                <h2>How It Works</h2>
                <div className={styles.stepByStep}>
                    <div className={styles.step}>
                        <h3>Rule 1: No Pints</h3>
                        <p>
                            To make the most of your beer crawl experience, only order 4 or 5 oz pours at each brewery.
                            As a pro-tip, consider ordering a sampler to share with your party.
                        </p>
                    </div>
                    <div className={styles.step}>
                        <h3>Rule 2: One Pour Per Brewery</h3>
                        <p>
                            To truly explore the wide variety of flavors Ballard has to offer, limit yourself to just
                            one pour per brewery. This allows you to visit more breweries and taste a greater range of
                            beers.
                        </p>
                    </div>
                    <div className={styles.step}>
                        <h3>Rule 3: Limit Your Stay</h3>
                        <p>
                            To optimize the number of different places you can visit, try to limit your stay at each
                            brewery to under 30 minutes. This way, you can fully immerse yourself in the local beer
                            scene without spending too much time at a single location.
                        </p>
                    </div>
                </div>
            </section>

            {/* FAQ */}
            <section>
                <h2>FAQ</h2>
                <p>
                    <strong>Q1:</strong> How do I join the No Pints Beer Crawl?
                </p>
                <p>
                    <strong>A1:</strong> The No Pints Beer Crawl is a self-driven experience. Simply follow our
                    recommendations and key rules to create your own beer crawl adventure in the city of your choice.
                </p>

                <p>
                    <strong>Q2:</strong> How much does it cost to participate in the beer crawl?
                </p>
                <p>
                    <strong>A2:</strong> The cost will depend on the prices at each brewery, which can vary. However,
                    since you're only ordering small pours or samplers, the overall cost should be more affordable than
                    a traditional beer crawl with full pints.
                </p>

                <p>
                    <strong>Q3:</strong> Is there a specific route or order to visit the breweries?
                </p>
                <p>
                    <strong>A3:</strong> There is no specific route or order you must follow. Feel free to visit the
                    breweries in any order that suits you. Our interactive map can help you plan the most convenient
                    path based on your location and preferences.
                </p>

                <p>
                    <strong>Q4:</strong> Can I participate in the beer crawl with a large group of friends?
                </p>
                <p>
                    <strong>A4:</strong> Yes! The No Pints Beer Crawl is perfect for groups of friends who want to
                    explore the local beer scene together. Sharing samplers with your group allows you to taste a
                    variety of beers and enjoy a fun, social experience.
                </p>

                <p>
                    <strong>Q5:</strong> What if I have special dietary restrictions or preferences?
                </p>
                <p>
                    <strong>A5:</strong> Many breweries offer a range of beer styles, including gluten-free or
                    non-alcoholic options. We recommend checking the brewery's website or contacting them directly to
                    inquire about their offerings and accommodate your dietary needs.
                </p>

                <p>
                    <strong>Q6:</strong> Are the breweries family-friendly?
                </p>
                <p>
                    <strong>A6:</strong> Some breweries may be family-friendly and offer non-alcoholic beverages and
                    food options, while others might cater exclusively to adults. We suggest checking the brewery's
                    website or contacting them directly to learn more about their family-friendliness.
                </p>
            </section>
        </div>
    );
};

export default SanDiegoCityPage;
