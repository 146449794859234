// src/components/Testimonials.tsx

import React from "react";
import styles from "../styles/testimonials.module.css";

interface Props {}

const Testimonials: React.FC<Props> = () => {
    const testimonials = [
        {
            name: "Rueben Henderson",
            quote: "I never thought a beer crawl without pints could be so enjoyable! This was a fantastic way to explore the city and taste different beers.",
            location: "Seattle, WA",
        },
        {
            name: "Kaitlin Mooney",
            quote: "No Pints Beer Crawl gave us an amazing experience! We visited several breweries and sampled some great beers. Highly recommended!",
            location: "Portland, OR",
        },
        {
            name: "Hugh Lawson",
            quote: "The sampler beer crawl was a fun and unique way to spend a day with friends. The breweries were fantastic, and the beer selection was superb.",
            location: "Austin, TX",
        },
    ];

    return (
        <section className={styles.testimonials}>
            <h2>What Our Adventurers Say</h2>
            <div className={styles.testimonialContainer}>
                {testimonials.map((testimonial, index) => (
                    <div key={index} className={styles.testimonial}>
                        <h3>{testimonial.name}</h3>
                        <p>{testimonial.quote}</p>
                        <small>{testimonial.location}</small>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Testimonials;
