// src/pages/ChicagoComingSoonPage.tsx

import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/ComingSoonPage.module.css";

const ChicagoComingSoonPage: React.FC = () => {
    return (
        <div className={styles.comingSoonPage}>
            <h1>Chicago - Coming Soon</h1>
            <p>
                Get ready, Chicago! The No Pints Beer Crawl experience will soon be arriving in the Windy City. With a
                thriving craft beer scene and an abundance of diverse breweries, Chicago is the perfect destination for
                our next beer crawl adventure.
            </p>
            <p>
                Our founders, <Link to="/about-us">Sten and Martha</Link>, are actively traveling and exploring the
                Chicago beer scene to curate the best recommendations for you. Stay tuned for updates and get ready to
                embark on a unique beer crawl experience in Chicago.
            </p>
        </div>
    );
};

export default ChicagoComingSoonPage;
