// src/pages/cityPage.tsx

import React from "react";
import styles from "../styles/cityPage.module.css";
import MAP from "../images/map_ballard.png";
import BALLARD_PNG from "../images/ballard.png";

interface Props {}

const BallardCityPage: React.FC<Props> = () => {
    return (
        <div className={styles.cityPage}>
            <h1>Ballard Beer Crawl</h1>

            {/* City Introduction */}
            <section>
                <p>
                    Ballard, a historic neighborhood in Seattle, Washington, is known for its vibrant atmosphere,
                    beautiful waterfront views, and thriving craft beer scene. With a rich history in maritime and
                    Scandinavian culture, Ballard has transformed into a must-visit destination for beer enthusiasts.
                    The No Pints Beer Crawl in Ballard takes you on a journey through some of the most popular breweries
                    in the city, offering a taste of the local flavor and charm.
                </p>
                <img
                    src={BALLARD_PNG}
                    style={{ maxWidth: "80%", margin: "auto", display: "flex" }}
                    alt="Ballard, Seattle"
                />
            </section>

            {/* Recommended Breweries */}
            <section>
                <h2>Recommended Breweries</h2>
                <ul>
                    <li>
                        <strong>Reuben's Brews:</strong> Founded in 2012, Reuben's Brews is a family-owned brewery
                        offering a wide variety of beers. Known for their award-winning Robust Porter and innovative
                        brews, Reuben's Brews is a must-visit for every beer enthusiast.
                    </li>
                    <li>
                        <strong>Stoup Brewing:</strong> Stoup Brewing is a craft brewery located in the heart of
                        Ballard. With a focus on both German and American styles, Stoup Brewing is dedicated to
                        providing high-quality, flavorful beers in a welcoming atmosphere.
                    </li>
                    <li>
                        <strong>Lucky Envelope Brewing:</strong> Inspired by their cultural heritage, Lucky Envelope
                        Brewing combines traditional brewing techniques with innovative recipes. They offer a diverse
                        selection of beers, from classic styles to experimental brews.
                    </li>
                    <li>
                        <strong>Populuxe Brewing:</strong> Populuxe Brewing is a small, artisanal brewery offering a
                        wide range of handcrafted beers. Their taproom features a large outdoor patio, making it the
                        perfect spot for socializing and sampling their creative brews.
                    </li>
                    <li>
                        <strong>Obec Brewing:</strong> Obec Brewing, which means "community" in Czech, offers a cozy
                        taproom and a selection of European-inspired beers. With a focus on traditional Czech and
                        Belgian styles, Obec Brewing provides a unique beer experience.
                    </li>
                    <li>
                        <strong>Hales Ales Brewery & Pub:</strong> Hales Ales is one of the oldest independently owned
                        breweries in the Pacific Northwest. They offer a wide range of ales, lagers, and barrel-aged
                        beers, all crafted with a commitment to quality and innovation.
                    </li>
                </ul>
            </section>
            {/* Beer Crawl Map */}
            <section>
                <h2>Beer Crawl Map</h2>
                <div className={styles.map}>{<img src={MAP} />}</div>
            </section>

            {/* How It Works */}
            <section>
                <h2>How It Works</h2>
                <div className={styles.stepByStep}>
                    <div className={styles.step}>
                        <h3>Rule 1: No Pints</h3>
                        <p>
                            To make the most of your beer crawl experience, only order 4 or 5 oz pours at each brewery.
                            As a pro-tip, consider ordering a sampler to share with your party.
                        </p>
                    </div>
                    <div className={styles.step}>
                        <h3>Rule 2: One Pour Per Brewery</h3>
                        <p>
                            To truly explore the wide variety of flavors Ballard has to offer, limit yourself to just
                            one pour per brewery. This allows you to visit more breweries and taste a greater range of
                            beers.
                        </p>
                    </div>
                    <div className={styles.step}>
                        <h3>Rule 3: Limit Your Stay</h3>
                        <p>
                            To optimize the number of different places you can visit, try to limit your stay at each
                            brewery to under 30 minutes. This way, you can fully immerse yourself in the local beer
                            scene without spending too much time at a single location.
                        </p>
                    </div>
                </div>
            </section>

            {/* FAQ */}
            <section>
                <h2>FAQ</h2>
                <p>
                    <strong>Q1:</strong> How do I join the No Pints Beer Crawl?
                </p>
                <p>
                    <strong>A1:</strong> The No Pints Beer Crawl is a self-driven experience. Simply follow our
                    recommendations and key rules to create your own beer crawl adventure in the city of your choice.
                </p>

                <p>
                    <strong>Q2:</strong> How much does it cost to participate in the beer crawl?
                </p>
                <p>
                    <strong>A2:</strong> The cost will depend on the prices at each brewery, which can vary. However,
                    since you're only ordering small pours or samplers, the overall cost should be more affordable than
                    a traditional beer crawl with full pints.
                </p>

                <p>
                    <strong>Q3:</strong> Is there a specific route or order to visit the breweries?
                </p>
                <p>
                    <strong>A3:</strong> There is no specific route or order you must follow. Feel free to visit the
                    breweries in any order that suits you. Our interactive map can help you plan the most convenient
                    path based on your location and preferences.
                </p>

                <p>
                    <strong>Q4:</strong> Can I participate in the beer crawl with a large group of friends?
                </p>
                <p>
                    <strong>A4:</strong> Yes! The No Pints Beer Crawl is perfect for groups of friends who want to
                    explore the local beer scene together. Sharing samplers with your group allows you to taste a
                    variety of beers and enjoy a fun, social experience.
                </p>

                <p>
                    <strong>Q5:</strong> What if I have special dietary restrictions or preferences?
                </p>
                <p>
                    <strong>A5:</strong> Many breweries offer a range of beer styles, including gluten-free or
                    non-alcoholic options. We recommend checking the brewery's website or contacting them directly to
                    inquire about their offerings and accommodate your dietary needs.
                </p>

                <p>
                    <strong>Q6:</strong> Are the breweries family-friendly?
                </p>
                <p>
                    <strong>A6:</strong> Some breweries may be family-friendly and offer non-alcoholic beverages and
                    food options, while others might cater exclusively to adults. We suggest checking the brewery's
                    website or contacting them directly to learn more about their family-friendliness.
                </p>
            </section>
        </div>
    );
};

export default BallardCityPage;
