// src/components/Header.tsx

import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../styles/header.module.css";
import LOGO from "../images/logo.png";

const Header: React.FC = () => {
    const [isNavVisible, setIsNavVisible] = useState(false);

    const toggleNav = () => {
        setIsNavVisible(!isNavVisible);
    };

    return (
        <header className={styles.header}>
            <h1 style={{ display: "flex", alignContent: "center", alignItems: "center" }}>
                <img
                    src={LOGO}
                    className={styles.logoicon}
                    alt="No Pints Beer Crawl"
                    style={{ maxWidth: "50px", margin: "0px 4px" }}
                />

                <Link to="/">No Pints Beer Crawl</Link>
            </h1>
            <nav className={`${styles.navbar} ${isNavVisible ? styles.show : ""}`}>
                <Link to="/">Home</Link>
                <Link to="/ballard">Ballard</Link>
                <Link to="/sandiego">San Diego</Link>
                <Link to="/chicago">Chicago (Coming Soon)</Link>
                <Link to="/about-us">About</Link>
            </nav>
            <div className={styles.menuIcon} onClick={toggleNav}>
                {/* material icon for a menu bar*/}
                <i className="material-icons">menu</i>
            </div>
        </header>
    );
};

export default Header;
